import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDMYAprlzPEAO8f3iRvuNd64blQl-_-k8U",
  authDomain: "startupdata-40051.firebaseapp.com",
  projectId: "startupdata-40051",
  storageBucket: "startupdata-40051.appspot.com",
  messagingSenderId: "646800392516",
  appId: "1:646800392516:web:5dd9c5b723e0cf09751711",
  measurementId: "G-BQP43Q5PD7"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
