import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import './NotFoundPage.css';

function NotFoundPage() {
    return (
        <div className="not-found-container">
            <div className="not-found-content">
                <h1 className="error-code">404</h1>
                <p className="error-message">Oops! The page you're looking for doesn't exist.</p>
                <Link to="/" className="home-link">
                    <Button
                        variant="dark"
                        size="lg"
                        className="back-button"
                    >
                        Back to Homepage
                    </Button>
                </Link>
            </div>
        </div>
    );
}

export default NotFoundPage;
