import React, { useState, useEffect, useRef } from "react";
import { Navbar, Button, Container, Row, DropdownButton, Dropdown } from "react-bootstrap";
import { Outlet, NavLink, useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Dashboard = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const location = useLocation();  // Get current location
  const isFirstRender = useRef(true);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    document.body.classList.toggle("no-scroll", !isToggled);
  };

  const handleResize = () => {
    if (window.innerWidth >= 992) {
      setIsToggled(false);
    } else {
      setIsToggled(true);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("username");
    setUsername("");
    navigate("/LoginAdmin");
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const savedUsername = localStorage.getItem("username");
    if (savedUsername) {
      setUsername(savedUsername);
    } else {
      navigate("/LoginAdmin");
    }
  }, [navigate]);

  useEffect(() => {
    if (isFirstRender.current && location.pathname === "/dashboard") {
      navigate("/dashboard/AddProducts"); // Ensure the full path is given
      isFirstRender.current = false;
    }
  }, [navigate, location]);


  return (
    <Container fluid>
      <Row>
        <div
          className={`d-flex p-0 ${isToggled ? "toggled" : ""}`}
          id="wrapper"
        >
          <div
            className={`border-end bg-white ${isToggled ? "show-sidebar" : ""}`}
            id="sidebar-wrapper"
          >
            <div className="sidebar-heading bg-light p-3 fs-4 fw-bold">
              Admin Dashboard
            </div>
            <div className="list-group list-group-flush">
              <NavLink
                to="AddProducts"
                className={({ isActive }) =>
                  `list-group-item list-group-item-action list-group-item-light p-3 fs-6 fw-bold ${isActive ? "active" : ""}`
                }
              >
                Add Products
              </NavLink>

              <NavLink
                to="CategoriesItemsAdmin"
                className={({ isActive }) =>
                  `list-group-item list-group-item-action list-group-item-light p-3 fs-6 fw-bold ${isActive ? "active" : ""}`
                }
              >
                Categories Items
              </NavLink>

              <NavLink
                to="CategoriesImagesAdmin1"
                className={({ isActive }) =>
                  `list-group-item list-group-item-action list-group-item-light p-3 fs-6 fw-bold ${isActive ? "active" : ""}`
                }
              >
                Categories Images1
              </NavLink>

              <NavLink
                to="CategoriesSliderAdmin1"
                className={({ isActive }) =>
                  `list-group-item list-group-item-action list-group-item-light p-3 fs-6 fw-bold ${isActive ? "active" : ""}`
                }
              >
                Categories Slider
              </NavLink>

              <NavLink
                to="CategoriesImagesAdmin2"
                className={({ isActive }) =>
                  `list-group-item list-group-item-action list-group-item-light p-3 fs-6 fw-bold ${isActive ? "active" : ""}`
                }
              >
                Categories Images2
              </NavLink>

              <NavLink
                to="BlogAdmin1"
                className={({ isActive }) =>
                  `list-group-item list-group-item-action list-group-item-light p-3 fs-6 fw-bold ${isActive ? "active" : ""}`
                }
              >
                Add Blog
              </NavLink>
            </div>
          </div>

          {/* Overlay */}
          {isToggled && <div className="overlay" onClick={handleToggle}></div>}

          <div id="page-content-wrapper">
            <Navbar className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <Button
                  className="btn tglbtn1 ms-0"
                  id="sidebarToggle"
                  onClick={handleToggle}
                >
                  <i className={`fas ${isToggled ? "fa-bars" : "fa-times"}`}></i>
                </Button>
                <DropdownButton
                  align="end"
                  title={<span><i className="fas fa-user"></i> {username}</span>}
                  id="dropdown-menu-align-end"
                  className="ms-3 profile_icn"
                  variant="primary"
                >
                  <Dropdown.Item onClick={handleLogout}>
                    <i className="fas fa-sign-out-alt me-2"></i> Logout
                  </Dropdown.Item>
                </DropdownButton>

              </div>
            </Navbar>
            <div className="container-fluid mt-3">
              <Outlet />
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Dashboard;