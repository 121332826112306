import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import MUIDataTable from "mui-datatables";
import { Modal, Button } from "react-bootstrap";
import {
  collection,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const AddProducts = () => {
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editProductId, setEditProductId] = useState(null);

  useEffect(() => {
    const unsubscribeProducts = onSnapshot(
      collection(db, "products"),
      (snapshot) => {
        const productsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(productsData);
      }
    );

    const unsubscribeCategories = onSnapshot(
      collection(db, "categories"),
      (snapshot) => {
        const categoriesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);
      }
    );

    return () => {
      unsubscribeProducts();
      unsubscribeCategories();
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      category: "",
      price: "",
      offer: "",
      imageUrl: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Product name is required"),
      category: Yup.string().required("Category is required"),
      price: Yup.number()
        .required("Price is required")
        .positive("Price must be positive"),
      offer: Yup.number()
        .min(0, "Offer must be at least 0")
        .max(100, "Offer cannot exceed 100"),
      imageUrl: Yup.string()
        .url("Invalid URL format")
        .required("Image URL is required"),
    }),

    onSubmit: async (values) => {
      try {
        const newProduct = {
          ...values,
          image: file ? [file] : [], // Ensure this matches how you store images
        };

        if (isEditing) {
          await updateDoc(doc(db, "products", editProductId), newProduct);
          toast.success("Product updated successfully!", {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 1000,
          });
        } else {
          await addDoc(collection(db, "products"), newProduct);
          toast.success("Product added successfully!", {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 1000,
          });
        }

        formik.resetForm();
        setFile(null);
        setFileError("");
        setShowForm(false);
        setIsEditing(false);
        setEditProductId(null);
      } catch (error) {
        console.error("Error saving product:", error);
        toast.error("Error saving product!", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          autoClose: 1000,
        });
      }
    },
  });

  const setImage = (event) => {
    const selectedFile = event.target.files[0];


    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFile(reader.result);
        setFileError(""); // Clear any previous errors
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const removeImage = () => {
    setFile(null);
    setFileError("");
  };

  const handleView = (id) => {
    const productToView = data.find((item) => item.id === id);
    const finalPrice = Math.round(
      productToView.price - (productToView.price * productToView.offer) / 100
    );
    setSelectedProduct({ ...productToView, finalPrice });
    setShowDetailModal(true);
  };

  const handleEdit = (id) => {
    const productToEdit = data.find((item) => item.id === id);
    setIsEditing(true);
    setEditProductId(id);
    setFile(productToEdit.image ? productToEdit.image[0] : null);

    formik.setValues({
      name: productToEdit.name,
      category: productToEdit.category,
      price: productToEdit.price,
      offer: productToEdit.offer,
      imageUrl: productToEdit.imageUrl,
      image: productToEdit.image,
    });

    setShowForm(true);
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this product?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await deleteDoc(doc(db, "products", id));
              toast.success("Product deleted successfully!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                autoClose: 1000,
              });
            } catch (error) {
              console.error("Error deleting product:", error);
              toast.error("Error deleting product!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,

                autoClose: 1000
              });
            }
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div className="text-truncate overflow-hidden" style={{ width: "150px" }}>
            {value}
          </div>
        ),
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <img
            src={value[0]}
            alt="Product"
            className="img-fluid custom-img"
            style={{ width: "50px", height: "auto" }}
          />
        ),
      },
    },
    {
      name: "imageUrl",
      label: "Image URL",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) {
            return (
              <a
                href={value}
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"              >
                View Url
              </a>
            );
          } else {
            return "";
          }
        },
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "offer",
      label: "Offer(%)",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "finalPrice",
      label: "Final Price",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const row = tableMeta.rowData;
          const price = row[4]; // Price column (updated index due to new column)
          const offer = row[5]; // Offer column (updated index due to new column)
          const finalPrice = Math.round(price - (price * offer) / 100);
          return `₹${finalPrice}`;
        },
      },
    },

    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div className="d-flex">
            <button
              className="btn btn-info me-1"
              onClick={() => handleView(value)}
            >
              View
            </button>
            <button
              className="btn btn-primary me-1"
              onClick={() => handleEdit(value)}
            >
              Edit
            </button>
            <button
              className="btn btn-danger"
              onClick={() => handleDelete(value)}
            >
              Delete
            </button>
          </div>
        ),
      },
    },
  ];

  return (
    <div>
      <ToastContainer />
      <Button
        variant="success"
        onClick={() => setShowForm(true)}
        className="mb-4"
      >
        Add New Product
      </Button>
      <MUIDataTable title={"Product List"} data={data} columns={columns} />
      <Modal show={showForm} onHide={() => setShowForm(false)} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Product" : "Add New Product"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-2">
              <label htmlFor="name"><strong>Product Name</strong></label>
              <input
                id="name"
                name="name"
                type="text"
                className="form-control"
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-danger">{formik.errors.name}</div>
              ) : null}
            </div>
            <div className="form-group mb-2">
              <label htmlFor="category"><strong>Category</strong></label>
              <select
                id="category"
                name="category"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.category}
              >
                <option value="">Select Category</option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.name}>
                    {cat.name}
                  </option>
                ))}
              </select>
              {formik.touched.category && formik.errors.category ? (
                <div className="text-danger">{formik.errors.category}</div>
              ) : null}
            </div>
            <div className="form-group mb-2">
              <label htmlFor="price"><strong>Price</strong></label>
              <input
                id="price"
                name="price"
                type="number"
                className="form-control"
                {...formik.getFieldProps("price")}
              />
              {formik.touched.price && formik.errors.price ? (
                <div className="text-danger">{formik.errors.price}</div>
              ) : null}
            </div>
            <div className="form-group mb-2">
              <label htmlFor="offer"><strong>Offer (%)</strong></label>
              <input
                id="offer"
                name="offer"
                type="number"
                className="form-control"
                {...formik.getFieldProps("offer")}
              />
              {formik.touched.offer && formik.errors.offer ? (
                <div className="text-danger">{formik.errors.offer}</div>
              ) : null}
            </div>
            <div className="form-group mb-2">
              <label htmlFor="imageUrl"><strong>Image URL</strong></label>
              <input
                id="imageUrl"
                name="imageUrl"
                type="text"
                className="form-control"
                {...formik.getFieldProps("imageUrl")}
              />
              {formik.touched.imageUrl && formik.errors.imageUrl ? (
                <div className="text-danger">{formik.errors.imageUrl}</div>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="image"><strong>Image</strong></label>
              <input
                id="image"
                name="image"
                type="file"
                className="form-control"
                onChange={setImage}
              />
              {fileError && <div className="text-danger">{fileError}</div>}
              {file && (
                <div>
                  <img
                    src={file}
                    alt="Preview"
                    className="mt-2"
                    style={{ width: "100%", height: "auto" }}
                  />
                  <button
                    type="button"
                    className="btn btn-danger mt-2"
                    onClick={removeImage}
                  >
                    Remove Image
                  </button>
                </div>
              )}
            </div>
            <Button type="submit" variant="primary" className="mt-2">
              {isEditing ? "Update Product" : "Add Product"}
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct ? (
            <div>
              <p><strong>Product Name:</strong> {selectedProduct.name}</p>
              <p><strong>Category:</strong> {selectedProduct.category}</p>
              <p><strong>Price:</strong> ₹{selectedProduct.price}</p>
              <p><strong>Offer:</strong> {selectedProduct.offer}%</p>
              <p><strong>Final Price:</strong> ₹{Math.round(selectedProduct.finalPrice)}</p>
              {selectedProduct.imageUrl && (
                <p>
                  ImageURL:
                  <a
                    variant="primary"
                    href={selectedProduct.imageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-3 ms-1"
                    style={{ textDecoration: "none" }}
                  >
                    View URL
                  </a>
                </p>
              )}
              {selectedProduct.image && (
                <img
                  src={selectedProduct.image[0]}
                  alt="Product"
                  style={{ width: "100%", height: "auto" }}
                />
              )}

            </div>
          ) : (
            <p>No product details available</p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddProducts;