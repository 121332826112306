import React, { useState, useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./firebaseConfig";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function BlogCard1({ excludeId }) {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "blogs"), (snapshot) => {
      const blogsData = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((blog) => blog.id !== excludeId);
      setBlogs(blogsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [excludeId]);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <Container fluid>
      <div className="blogmain mt-5 mb-4">
        {loading ? (
          <Container className="d-flex flex-column align-items-center justify-content-center">
            <Spinner animation="border" variant="primary" />
            <p className="mt-3">Loading Blogs...</p>
          </Container>
        ) : (
          <Row>
            {blogs.map((blog) => (
              <Col
                key={blog.id}
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                className="mb-4"
              >
                <div className="card d-flex h-100 rounded-0 border-0">
                  <div className="blogcard1 d-flex">
                    <img
                      alt={blog.title}
                      src={blog.image || "placeholder-image-url"}
                      className="incardimg img-fluid"
                      style={{ height: "auto", width: "100%", objectFit: "cover" }}
                    />
                  </div>
                  <div className="card-body p-0">
                    <h6
                      className="cardtxt3 mt-4 fs-4 fw-bold"
                    >
                      {blog.title || "No title available"}

                    </h6>
                    <ReactQuill
                      value={truncateText(blog.content || "No content available", 100)}
                      readOnly
                      theme="bubble"
                      className="mb-0 p-0"
                    />
                    <Link
                      to={`/blogs/${blog.id}`}
                      className="text-primary p-0 text-decoration-underline" style={{ fontSize: "13px" }}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </Container>
  );
}
