import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "./image/bargening.png";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { FaAmazon, FaShoppingBag, FaShoppingCart, FaTag, FaShoppingBasket } from 'react-icons/fa';

export default function Footer() {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check this out!',
          text: 'Check out this great website:',
          url: window.location.href,
        });
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      console.log('Web Share API is not supported in this browser.');
    }
  };

  return (
    <Container fluid>
      <footer className="section bg-footer container-fluid mt-4 mb-4">
        <Row className="justify-content-center">
          <Col lg={4} md={4} sm={6}>
            <ul className="list-inline">
              <li>
                <Link to="/">
                  <img
                    src={logo}
                    alt="Company Logo"
                    className="navbar-logo"
                    style={{ width: "170px" }}
                  />
                </Link>
              </li>
            </ul>
            <ul className="list-inline">
              <li>
                <p className="footertxt" style={{ fontSize: "14px" }}>
                  We help people to provide you the best product at the lowest price.
                </p>
              </li>
            </ul>
            <ul className="">
              <li style={{ listStyleType: 'disc', display: 'list-item' }}>
                <p>Share with your friends and earn money!</p>
              </li>
            </ul>
            <ul className="list-inline mt-4">
              <li>
                <div
                  className="link-button-container d-flex align-items-center mt-3"
                  onClick={handleShare}
                  aria-label="Share"
                  style={{ cursor: "pointer" }}
                >
                  <div className="icon-box p-1 d-flex me-2">
                    <button
                      className="btn btn-outline rounded-circle"
                      style={{ pointerEvents: "none" }}
                    >
                      <i
                        className="bi bi-share-fill share_button_footer fs-6"
                      ></i>
                    </button>
                  </div>
                  <div>
                    <div className="link-title fw-bold">Share With Friends</div>
                  </div>
                </div>
              </li>
            </ul>
          </Col>
          {/* <Col lg={2} md={6} sm={12} className="mb-0 mb-lg-0 order-lg-1 order-md-4">
            <h6 className="footer-heading mt-2">About Us</h6>
            <ul className="social-links mt-4 list-unstyled">
              <li className="mb-1">
                <Link to="/Home" className="text-decoration-none text-dark">Home</Link>
              </li>
              <li className="mb-1">
                <Link to="/product" className="text-decoration-none text-dark">Products</Link>
              </li>
              <li className="mb-1">
                <Link to="/Blog" className="text-decoration-none text-dark">Blog</Link>
              </li>
            </ul>
          </Col> */}
          <Col lg={4} md={4} sm={6} className="mb-0 mb-lg-0 order-lg-1 order-md-1 order-2 order-sm-2">
            <h6 className="footer-heading mt-2 fs-5">Our Partner's</h6>
            <ul className="social-links mt-4 list-unstyled">
              <Row className="g-2">
                {[
                  { to: "https://amzn.to/3XfDl5q", label: "Amazon", icon: <FaAmazon /> },
                  { to: "https://www.flipkart.com", label: "Flipkart", icon: <FaShoppingBag /> },
                  { to: "https://www.meesho.com", label: "Meesho", icon: <FaShoppingCart /> },
                  { to: "https://www.ajio.com", label: "Ajio", icon: <FaTag /> },
                  { to: "https://www.myntra.com", label: "Myntra", icon: <FaShoppingBasket /> },
                ].map((link, index) => (
                  <Col lg={6} md={6} sm={6} xs={6} key={index}>
                    <a href={link.to} target="_blank" rel="noopener noreferrer" className="social_link_footer">
                      <Button variant="outline-dark" className="w-100 d-flex align-items-center">
                        <span className="me-2">{link.icon}</span>
                        <span>
                          {link.label}
                        </span>
                      </Button>
                    </a>
                  </Col>
                ))}
              </Row>
            </ul>
          </Col>
          <Col lg={4} md={4} sm={6} className="mb-0 mb-lg-0 order-lg-1 order-md-1 order-1 order-sm-1">
            <h6 className="footer-heading mt-2 fs-5">Information</h6>
            <ul className="social_links mt-4 list-unstyled">
              {[
                { href: "https://t.me/your-telegram-group", icon: "bi-telegram", text: "Join Telegram Group" },
                { href: "https://www.instagram.com/barganing51/?utm_source=ig_web_button_share_sheet", icon: "bi-instagram", text: "Follow Instagram" },
                { href: "https://www.facebook.com/profile.php?id=61564668969534", icon: "bi-facebook", text: "Join Facebook" },
              ].map((social, index) => (
                <li className="social-item mb-2" key={index}>
                  <a
                    href={social.href}
                    className="social-link d-flex align-items-center p-2 text-decoration-none border rounded"
                    aria-label={social.text}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="social-icon p-2 me-2 d-flex align-items-center justify-content-center">
                      <i className={`bi ${social.icon} fs-5`}></i>
                    </div>
                    <div className="social-text fw-bold">{social.text}</div>
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </footer>

      <Row>
        <div className="footerend text-center py-2">
          <p className="mb-0">
            Copyright © 2024 all rights reserved by
            <a
              className="ms-1 text-decoration-none"
              href="https://liontouchwebpark.com/"
              aria-label="Liontouch Webpark"
            >
              Liontouch Webpark PVT.
            </a>
          </p>
        </div>
      </Row>
    </Container>
  );
}
