import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./firebaseConfig";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const smallScreenSliderSettings = {
  dots: false,
  arrows: false,
  fade: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 3000,
  cssEase: "linear",
  waitForAnimate: false,
};

const largeScreenSliderSettings = {
  dots: false,
  arrows: false,
  fade: false,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 2,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 3000,
  cssEase: "linear",
  waitForAnimate: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export default function CategoriesImageSlider1() {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "CategoriesSlider1"),
      (snapshot) => {
        const imagesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          image: doc.data().image,
          name: doc.data().name, // Assuming name is also part of the document
        }));
        setImages(imagesList);
      },
      (err) => {
        setError(err);
      }
    );

    return () => unsubscribe();
  }, []);

  if (error) return <p>Error loading images: {error.message}</p>;

  return (
    <Container fluid>
      <Row className="p-0 pb-2 pt-2 ps-lg-2 ps-1 pe-lg-2 pe-1">
        {/* Slider for small screens */}
        <div className="d-block d-md-none p-0">
          <Slider {...smallScreenSliderSettings}>
            {images.map((category) => (
              <Link
                to={`/CategoryProduct?category=${encodeURIComponent(
                  category.name
                )}`}
                key={category.id}
                className="d-flex justify-content-center"
              >
                <img
                  src={category.image}
                  alt={`Category ${category.name}`}
                  className="img-fluid img-contain"
                />
              </Link>
            ))}
          </Slider>
        </div>

        {/* Slider for large screens */}
        <div className="d-none d-md-block ps-0 pe-0">
          <Slider {...largeScreenSliderSettings}>
            {images.map((category) => (
              <Link
                to={`/CategoryProduct?category=${encodeURIComponent(
                  category.name
                )}`}
                key={category.id}
                className="d-flex justify-content-center"
              >
                <img
                  src={category.image}
                  alt={`Category ${category.name}`}
                  className="img-fluid img-cover"
                />
              </Link>
            ))}
          </Slider>
        </div>
      </Row>
    </Container>
  );
}

