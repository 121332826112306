import React from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaSearch } from 'react-icons/fa';
import {
  Container,
  Row,
  Col,
  Nav,
  Navbar,
  Form,
  InputGroup,
  Button,
  Offcanvas,
} from "react-bootstrap";
import logo from "./image/bargening.png";

export default function CustomNavbar({ onSearch }) {
  const navigate = useNavigate();
  const location = useLocation(); 

  const handleSearch = (query) => {
    navigate(`/product`);
    onSearch(query);
  };

  const isActiveLink = (path) => location.pathname === path;

  return (
    <Navbar className="bg2 bg-transperent py-3 shadow-sm" expand="md">
      <Container fluid className="justify-content-center">
        <Row className="w-100 align-items-center">
          <Col lg={3} md={3} xs={6} className="p-0">
            {/* Logo */}
            <Navbar.Brand as={Link} to="/">
              <img
                src={logo}
                alt="logo"
                className="navbar-logo"
                style={{ width: "170px" }}
              />
            </Navbar.Brand>
          </Col>

          <Col lg={9} md={9} xs={6} className="d-flex justify-content-end p-0">
            <Navbar.Toggle
              aria-controls="offcanvasNavbar"
              className="custom-navbar-toggle"
            />

            <Navbar.Offcanvas
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="start"
              className="bg-white w-auto"
            >
              <Offcanvas.Header closeButton>
                <Navbar.Brand as={Link} to="/">
                  <img
                    src={logo}
                    alt="logo"
                    className="navbar-logo"
                    style={{ width: "170px" }}
                  />
                </Navbar.Brand>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-start">
                  <Nav.Link
                    as={Link}
                    to="/"
                    className={isActiveLink('/') ? 'navlink active' : 'navlink'}
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/product"
                    className={isActiveLink('/product') ? 'navlink active' : 'navlink'}
                  >
                    Products
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/Blog"
                    className={isActiveLink('/Blog') ? 'navlink active' : 'navlink'}
                  >
                    Blog
                  </Nav.Link>
                </Nav>

                <Form className="d-flex ms-auto mt-3 mt-lg-0 mt-md-0 mt-xs-0">
                  <InputGroup>
                    <Form.Control
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                      onChange={(e) => handleSearch(e.target.value.toLowerCase())}
                    />
                    <Button variant="outline-secondary">
                      <FaSearch />
                    </Button>
                  </InputGroup>
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

CustomNavbar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

CustomNavbar.defaultProps = {
  onSearch: () => { },
};
