import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import MUIDataTable from "mui-datatables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import imageCompression from "browser-image-compression";

export default function CategoriesSliderAdmin1() {
  const [show, setShow] = useState(false);
  const [categoryImage, setCategoryImage] = useState(null);
  const [file, setFile] = useState(null);
  const [categoriesImages, setCategoriesImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const validationSchema = Yup.object({
    name: Yup.string().required("Category is required"),
    image: Yup.mixed().required("Category image is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      image: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!values.image) {
        toast.error("Please select an image for the category.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return;
      }

      const categoryData = {
        name: values.name,
        image: categoryImage,
      };

      try {
        if (isEdit) {
          const categoryDoc = doc(db, "CategoriesSlider1", editId);
          await updateDoc(categoryDoc, categoryData);
          toast.success("Category updated successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          await addDoc(collection(db, "CategoriesSlider1"), categoryData);
          toast.success("Category added successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }

        formik.resetForm();
        removeImage();
        handleClose();
      } catch (error) {
        console.error("Error adding/updating category: ", error);
        toast.error("Error adding/updating category.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    },
  });

  const handleClose = () => {
    setShow(false);
    setIsEdit(false);
    formik.resetForm();
    removeImage();
  };
  const handleShow = () => setShow(true);

  const setImage = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type.startsWith("image/")) {
        try {
          const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 800,
          };

          const compressedFile = await imageCompression(selectedFile, options);

          const reader = new FileReader();
          reader.onloadend = () => {
            setFile(reader.result);
            setCategoryImage(reader.result);
            formik.setFieldValue("image", compressedFile);
          };
          reader.readAsDataURL(compressedFile);
        } catch (error) {
          console.error("Error compressing image: ", error);
          toast.error("Failed to compress image.", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      } else {
        toast.error("Please select a valid image file.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    }
  };

  const removeImage = () => {
    setFile(null);
    setCategoryImage(null);
    formik.setFieldValue("image", null);
  };

  useEffect(() => {
    const unsubscribeImages = onSnapshot(
      collection(db, "CategoriesSlider1"),
      (snapshot) => {
        const categoriesImagesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategoriesImages(categoriesImagesData);
      }
    );
    const unsubscribeCategories = onSnapshot(
      collection(db, "categories"),
      (snapshot) => {
        const categoriesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);
      }
    );

    return () => {
      unsubscribeImages();
      unsubscribeCategories();
    };
  }, []);

  const handleEdit = async (id) => {
    const categoryToEdit = categoriesImages.find((item) => item.id === id);
    formik.setValues({
      name: categoryToEdit.name,
      image: categoryToEdit.image,
    });
    setFile(categoryToEdit.image);
    setCategoryImage(categoryToEdit.image);
    setIsEdit(true);
    setEditId(id);
    setShow(true);
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this category?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await deleteDoc(doc(db, "CategoriesSlider1", id));
              setCategoriesImages(
                categoriesImages.filter((item) => item.id !== id)
              );
              toast.success("Category deleted successfully!", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            } catch (error) {
              console.error("Error deleting category: ", error);
              toast.error("Failed to delete category.", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleView = (id) => {
    const categoryToView = categoriesImages.find((item) => item.id === id);
    setSelectedCategory(categoryToView);
    setShowDetailModal(true);
  };

  const columns = [
    {
      name: "name",
      label: "Category",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const category = categories.find((cat) => cat.name === value);
          return category ? category.name : "Unknown";
        },
      },
    },
    {
      name: "image",
      label: "Category Image",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <img
            src={value || "placeholder-image-url"}
            alt="Category"
            style={{ width: "80px", height: "auto", objectFit: "cover" }}
          />
        ),
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div className="d-flex">
            <Button
              variant="info"
              onClick={() => handleView(value)}
              className="btn btn-primary me-1"
            >
              View
            </Button>
            <Button
              className="btn btn-primary me-1"
              onClick={() => handleEdit(value)}
            >
              Edit
            </Button>
            <Button
              className="btn btn-danger"
              onClick={() => handleDelete(value)}
            >
              Delete
            </Button>
          </div>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    responsive: "standard",
  };

  return (
    <div>
      <ToastContainer />
      <Button variant="success" onClick={handleShow} className="mb-4">
        Add New Image
      </Button>

      <Modal show={show} onHide={handleClose} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit Image" : "Add New Image"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="formCategoryImage">
              <Form.Group controlId="formCategorySelect">
                <Form.Label className="fw-bold">Select Category</Form.Label>
                <Form.Control
                  as="select"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.name}
                >
                  <option value="">Select Category</option>
                  {categories.map((cat) => {
                    console.log(cat.name);
                    return (
                      <option key={cat.id} value={cat.name}>
                        {cat.name}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Label className="fw-bold">Category Image</Form.Label>
              <Form.Control
                id="image"
                type="file"
                onChange={setImage}
                isInvalid={formik.touched.image && formik.errors.image}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.image}
              </Form.Control.Feedback>
              {file && (
                <div className="mt-2">
                  <img
                    src={file}
                    alt="Selected"
                    style={{ width: "100%", height: "auto" }}
                  />
                  <Button
                    variant="danger"
                    className="mt-2"
                    onClick={removeImage}
                  >
                    Remove Image
                  </Button>
                </div>
              )}
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-2">
              {isEdit ? "Update Category" : "Add Category"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Slider Image Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCategory && (
            <div>
              <p>
                <strong>Category:</strong>
                {selectedCategory.name
                  ? categories.find((cat) => cat.name === selectedCategory.name)
                      ?.name
                  : "Unknown"}
              </p>
              <img
                src={selectedCategory.image}
                alt="Category"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>

      <MUIDataTable
        title={"Categories List"}
        data={categoriesImages}
        columns={columns}
        options={options}
      />
    </div>
  );
}
