import React, { useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Homepage from "./Homepage";
import Blogpage from "./Blogpage";
import BlogDetails from "./BlogDetails";
import Dashboard from "./Components/Dashboard";
import AddProducts from "./Components/AddProducts";
import LoginAdmin from "./Components/LoginAdmin";
import CategoriesItemsAdmin from "./Components/CategoriesItemsAdmin";
import CategoriesImagesAdmin1 from "./Components/CategoriesImagesAdmin1";
import CategoriesSliderAdmin1 from "./Components/CategoriesSliderAdmin1";
import CategoriesImagesAdmin2 from "./Components/CategoriesImagesAdmin2";
import BlogAdmin1 from "./Components/BlogAdmin1";
import Shopproduct1 from "./Shopproduct1";
import CategoryProduct from "./CategoryProduct";
import ScrollToTopButton from "./ScrollToTopButton";
import NotFoundPage from "./NotFoundPage"; // Import NotFoundPage

function RoutesComponents() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("username") ? true : false
  );

  const PrivateRoute = ({ element }) => {
    return isAuthenticated ? element : <Navigate to="/LoginAdmin" replace />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/product" element={<Shopproduct1 />} />
        <Route path="/blog" element={<Blogpage />} />
        <Route path="/blogs/:id" element={<BlogDetails />} />
        <Route path="/LoginAdmin" element={<LoginAdmin setAuth={setIsAuthenticated} />} />
        <Route path="/CategoryProduct" element={<CategoryProduct />} />
        <Route path="/Dashboard/*" element={<PrivateRoute element={<Dashboard />} />}>
          <Route index element={<Navigate to="AddProducts" />} />
          <Route path="AddProducts" element={<AddProducts />} />
          <Route path="CategoriesItemsAdmin" element={<CategoriesItemsAdmin />} />
          <Route path="CategoriesImagesAdmin1" element={<CategoriesImagesAdmin1 />} />
          <Route path="CategoriesSliderAdmin1" element={<CategoriesSliderAdmin1 />} />
          <Route path="CategoriesImagesAdmin2" element={<CategoriesImagesAdmin2 />} />
          <Route path="BlogAdmin1" element={<BlogAdmin1 />} />
        </Route>
        <Route path="*"  element={<NotFoundPage />} />
      </Routes>
      <ScrollToTopButton />
    </BrowserRouter>
  );
}

export default RoutesComponents;
