import React, { useEffect, useState } from "react";
import { Container, Row, Spinner, Alert } from "react-bootstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebaseConfig";
import categoryimgdefault from "./image/order111.jpg03.jpeg";
import { Link } from "react-router-dom";

export default function CategoriesItems1() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        const categoriesList = querySnapshot.docs.map((doc) => doc.data());
        setCategories(categoriesList);
      } catch (error) {
        setError("Error fetching categories: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  if (loading) {
    return <div className="text-center pt-4"><Spinner animation="border" variant="primary" /></div>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <div className="text-center mt-lg-4 mb-lg-3 mt-3 mb-2 p-0">
          <div className="d-flex categoryy-container">
            <Link to="product" className="text-decoration-none">
              <div className="categoryyitem text-center">
                <img
                  className="categoryylink"
                  src={categoryimgdefault}
                  alt="Default Category"
                />
                <p className="categories text-dark fw-bold m-0">Categories</p>
              </div>
            </Link>
            {categories.map((category) => (
              <div
                key={category.name}
                className="categoryyitem text-center justify-content-center"
                style={{ width: "95px" }}
              >
                <Link to={`/CategoryProduct?category=${encodeURIComponent(category.name)}`} className="text-decoration-none text-dark">
                  <img
                    className="categoryylink"
                    src={category.image || categoryimgdefault}
                    alt={category.name}
                  />
                  <p className="categories_name m-0">
                    {category.name}
                  </p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </Row>
    </Container>
  );
}
