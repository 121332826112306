import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import {
    collection,
    onSnapshot,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import imageCompression from 'browser-image-compression';

const BlogAdmin1 = () => {
    const [blogs, setBlogs] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editBlogId, setEditBlogId] = useState(null);
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState("");

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "blogs"), (snapshot) => {
            const blogsData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setBlogs(blogsData);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const formik = useFormik({
        initialValues: {
            title: "",
            content: "",
            image: "",
            date: new Date().toISOString(),
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            content: Yup.string().required("Content is required"),
            image: Yup.string(),
        }),
        onSubmit: async (values) => {
            try {
                const newBlog = {
                    ...values,
                    image: file || "",
                    date: new Date().toISOString(),
                };

                if (isEditing) {
                    await updateDoc(doc(db, "blogs", editBlogId), newBlog);
                    toast.success("Blog updated successfully!");
                } else {
                    await addDoc(collection(db, "blogs"), newBlog);
                    toast.success("Blog added successfully!");
                }

                formik.resetForm();
                setFile(null);
                setFileError("");
                setShowForm(false);
                setIsEditing(false);
                setEditBlogId(null);
            } catch (error) {
                console.error("Error saving blog:", error);
                toast.error("Error saving blog!");
            }
        },
    });

    const setImage = async (event) => { 
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            try {
                const options = {
                    maxSizeMB: 2,
                    maxWidthOrHeight: 800,
                };

                const compressedFile = await imageCompression(selectedFile, options);

                const reader = new FileReader();
                reader.onloadend = () => {
                    setFile(reader.result);
                    setFileError("");
                };
                reader.readAsDataURL(compressedFile);
            } catch (error) {
                console.error("Error compressing image:", error);
                setFileError("Failed to compress image. Please try again.");
            }
        }
    };

    const removeImage = () => {
        setFile(null);
        setFileError("");
    };

    const handleView = (id) => {
        const blogToView = blogs.find((item) => item.id === id);
        setSelectedBlog(blogToView);
        setShowDetailModal(true);
    };

    const handleEdit = (id) => {
        const blogToEdit = blogs.find((item) => item.id === id);
        if (blogToEdit) {
            formik.setValues({
                title: blogToEdit.title || "",
                content: blogToEdit.content || "",
                image: blogToEdit.image || "",
                date: blogToEdit.date || new Date().toISOString(),
            });

            setFile(blogToEdit.image || "");

            setIsEditing(true);
            setEditBlogId(id);

            setShowForm(true);
        }
    };

    const handleDelete = (id) => {
        confirmAlert({
            title: "Confirm to Delete",
            message: "Are you sure you want to delete this blog?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            await deleteDoc(doc(db, "blogs", id));
                            toast.success("Blog deleted successfully!");
                        } catch (error) {
                            console.error("Error deleting blog:", error);
                            toast.error("Error deleting blog!");
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    };

    const columns = [
        {
            name: "title",
            label: "Title",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "content",
            label: "Content",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <span>{(value || "").substring(0, 100)}...</span>
                ),
            },
        },
        {
            name: "image",
            label: "Image",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => (
                    <img
                        src={value || ""}
                        alt="Blog"
                        className="img-fluid custom-img-size"
                    />
                ),
            },
        },
        {
            name: "date",
            label: "Published Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <span>{new Date(value).toLocaleDateString()}</span>
                ),
            },
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const id = blogs[tableMeta.rowIndex].id;
                    return (
                        <div className="d-flex">
                            <Button
                                className="btn btn-info me-1"
                                onClick={() => handleView(id)}
                            >
                                View
                            </Button>
                            <Button
                                className="btn btn-primary me-1"
                                onClick={() => handleEdit(id)}
                            >
                                Edit
                            </Button>
                            <Button
                                className="btn btn-danger"
                                onClick={() => handleDelete(id)}
                            >
                                Delete
                            </Button>
                        </div>
                    );
                },
            },
        },
    ];

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
            />
            <Button
                variant="success"
                onClick={() => setShowForm(true)}
                className="mb-4"
            >
                Add New Blog
            </Button>
            <MUIDataTable
                title={"Blog List"}
                data={blogs}
                columns={columns}
                options={{
                    filterType: "checkbox",
                    responsive: "standard",
                }}
            />
            <Modal show={showForm} onHide={() => setShowForm(false)} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? "Edit Blog" : "Add New Blog"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-group mb-2">
                            <p className="mb-0"><strong><label htmlFor="title">Title</label></strong></p>
                            <input
                                id="title"
                                name="title"
                                type="text"
                                className="form-control"
                                {...formik.getFieldProps("title")}
                            />
                            {formik.touched.title && formik.errors.title ? (
                                <div className="text-danger">{formik.errors.title}</div>
                            ) : null}
                        </div>
                        <div className="form-group mb-2">
                            <p className="mb-0"><strong><label htmlFor="content">Content</label></strong></p>
                            <ReactQuill
                                id="content"
                                name="content"
                                value={formik.values.content}
                                onChange={(value) => formik.setFieldValue("content", value)}
                                className="form-control"
                                style={{
                                    height: '180px',
                                }}
                            />
                            {formik.touched.content && formik.errors.content ? (
                                <div className="text-danger">{formik.errors.content}</div>
                            ) : null}
                        </div>

                        <div className="form-group mb-2">
                            <p className="mb-0"><strong><label htmlFor="content">Image</label></strong></p>
                            <input
                                id="image"
                                name="image"
                                type="file"
                                className="form-control"
                                onChange={setImage}
                            />
                            {file && (
                                <div className="mt-2">
                                    <img
                                        src={file}
                                        alt="Blog"
                                        className="img-fluid mt-1"
                                        style={{ width: "100%", height: "auto" }}
                                    />

                                    <button
                                        type="button"
                                        className="btn btn-danger mt-2"
                                        onClick={removeImage}
                                    >
                                        Remove Image
                                    </button>
                                </div>
                            )}
                            {fileError && <div className="text-danger">{fileError}</div>}
                        </div>
                        <Button type="submit" variant="primary">
                            {isEditing ? "Update Blog" : "Add Blog"}
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showDetailModal}
                onHide={() => setShowDetailModal(false)}
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Blog Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedBlog && (
                        <div>
                            <h5><strong>Title: </strong>{selectedBlog.title}</h5><br />
                            <h5><strong>Content: </strong><ReactQuill
                                value={selectedBlog.content}
                                readOnly={true}
                                theme="bubble"
                            />
                            </h5>
                            {selectedBlog.image && (
                                <img
                                    src={selectedBlog.image}
                                    alt="Blog"
                                    className="mt-1"
                                    style={{ width: "100%", height: "auto" }}
                                />
                            )}
                            <p><strong>Published Date:</strong> {new Date(selectedBlog.date).toLocaleDateString()}</p>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div >
    );
};

export default BlogAdmin1;
