import React from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebaseConfig";
import { Spinner, Alert, Container, Row, Col } from "react-bootstrap";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import BlogCard1 from "./Blogcard1";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function BlogDetails() {
    const { id } = useParams();
    const [blog, setBlog] = React.useState(null);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        const fetchBlog = async () => {
            try {
                const docRef = doc(db, "blogs", id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setBlog(docSnap.data());
                } else {
                    setError("Blog not found");
                }
            } catch (err) {
                setError("Failed to fetch blog details");
                console.error(err);
            }
        };

        fetchBlog();

        window.scrollTo(0, 0);
    }, [id]);

    if (error) {
        return (
            <Container className="d-flex flex-column align-items-center justify-content-center min-vh-100">
                <Alert variant="danger" className="text-center">
                    {error}
                </Alert>
            </Container>
        );
    }

    if (!blog) {
        return (
            <Container className="d-flex flex-column align-items-center justify-content-center min-vh-100">
                <Spinner animation="border" variant="primary" />
                <p className="mt-3">Loading blog details...</p>
            </Container>
        );
    }

    return (
        <>
            <Navbar />

            <Container className="py-4">
                <Row>
                    <Col md={12} lg={8} className="mx-auto">
                        <img
                            src={blog.image || "placeholder-image-url"}
                            alt="Blog"
                            className="img-fluid mb-2"
                            style={{ height: "auto", width: "100%", objectFit: "cover" }}
                        />
                        <p className="text-muted" style={{ fontSize: "13px" }}>{new Date(blog.date).toLocaleDateString()}</p>
                        <p className="fs-4 fw-bold text-dark mb-4">{blog.title}</p>
                        <ReactQuill
                            value={blog.content}
                            readOnly
                            theme="bubble"
                        />
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col md={12}>
                        <h4 className="mb-4">Related Blogs</h4>
                        <BlogCard1 excludeId={id} />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
}
