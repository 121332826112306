import "./App.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import CategoriesItems1 from "./CategoriesItems1";
import CategoriesImage1 from "./CategoriesImage1.js";
import CategoriesImageSlider1 from "./CategoriesImageSlider1";
import CategoriesImage2 from "./CategoriesImage2";


function Homepage() {
  return (
    <>
      <Navbar />
      <CategoriesItems1 />
      <CategoriesImage1 />
      <CategoriesImageSlider1 />
      <CategoriesImage2 />
      <Footer />
    </>
  );
}

export default Homepage;
