import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Form, Button, Offcanvas, Spinner } from "react-bootstrap";
import { db } from "./firebaseConfig";
import { collection, getDocs, query, limit, startAfter } from "firebase/firestore";
import { debounce } from 'lodash';
import Navbar from "./Navbar";
import Footer from "./Footer";
import ProductCard from "./ProductCard";

export default function Shopproduct1() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [sortOrder, setSortOrder] = useState("default");
  const [priceRange, setPriceRange] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [category] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await getDocs(collection(db, "categories"));
        const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data());
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories: ", error);
      }
    };

    fetchCategories();
  }, []);

  const fetchProducts = useCallback(async (startAfterDoc = null) => {
    if (loading) return;

    setLoading(true);

    try {
      let productsQuery = query(collection(db, "products"), limit(16));
      if (startAfterDoc) {
        productsQuery = query(productsQuery, startAfter(startAfterDoc));
      }

      const productsSnapshot = await getDocs(productsQuery);
      const productsData = productsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setProducts((prev) => [...prev, ...productsData]);

      const lastVisibleDoc = productsSnapshot.docs[productsSnapshot.docs.length - 1];
      setLastVisible(lastVisibleDoc);

      if (productsData.length < 16) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching products: ", error);
    } finally {
      setLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleScroll = debounce(() => {
    const bottom = window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 100;
    if (bottom && hasMore && !loading) {
      fetchProducts(lastVisible);
    }
  }, 200);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll, lastVisible, loading, hasMore]);

  const handleCategoryChange = (event) => {
    const { value, checked } = event.target;
    setSelectedCategories((prev) =>
      value === "all"
        ? checked
          ? categories.map((cat) => cat.name)
          : []
        : checked
          ? [...prev, value]
          : prev.filter((category) => category !== value)
    );
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handlePriceRangeChange = (event) => {
    setPriceRange(event.target.value);
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };


  const sortedProducts = products
    .filter((product) => {
      const finalPrice = Math.round(product.price - (product.price * product.offer) / 100);
      return (
        (selectedCategories.length === 0 || selectedCategories.includes(product.category)) &&
        (priceRange === "all" ||
          (priceRange === "0-100" && finalPrice >= 0 && finalPrice <= 100) ||
          (priceRange === "100-1000" && finalPrice > 100 && finalPrice <= 1000) ||
          (priceRange === "1000-" && finalPrice > 1000)) &&
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    })
    .sort((a, b) => {
      const finalPriceA = Math.round(a.price - (a.price * a.offer) / 100);
      const finalPriceB = Math.round(b.price - (b.price * b.offer) / 100);

      if (sortOrder === "high-to-low") {
        return finalPriceB - finalPriceA;
      } else if (sortOrder === "low-to-high") {
        return finalPriceA - finalPriceB;
      }
      return 0;
    });

  return (
    <>
      <Navbar onSearch={handleSearchChange} />
      <Container fluid>
        <Row className="justify-content-start">
          <Col xs={12} lg={3} className="d-lg-block d-none">
            <div className="sidebar p-3">
              <h6 className="sidebar-heading fw-bold fs-5 pb-2 p-0 text-start">Filter by Category</h6>
              <Form>
                <Form.Check
                  type="checkbox"
                  id="category-all"
                  label="All Categories"
                  value="all"
                  onChange={handleCategoryChange}
                  checked={selectedCategories.length === categories.length}
                  className="mb-1"
                />
                {categories.map((category) => (
                  <Form.Check
                    key={category.name}
                    type="checkbox"
                    id={`category-${category.name}`}
                    label={category.name}
                    value={category.name}
                    onChange={handleCategoryChange}
                    checked={selectedCategories.includes(category.name)}
                    className="mb-1"
                  />
                ))}
              </Form>
              <Form.Group className="mt-3">
                <Form.Label>Price Range</Form.Label>
                <Form.Control
                  as="select"
                  className="border-light"
                  onChange={handlePriceRangeChange}
                >
                  <option value="all">All</option>
                  <option value="0-100">0 - 100</option>
                  <option value="100-1000">100 - 1000</option>
                  <option value="1000-">1000+</option>
                </Form.Control>
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>Sort by Price</Form.Label>
                <Form.Control
                  as="select"
                  className="border-light"
                  onChange={handleSortChange}
                >
                  <option value="default">Default</option>
                  <option value="low-to-high">Price: Low to High</option>
                  <option value="high-to-low">Price: High to Low</option>
                </Form.Control>
              </Form.Group>
            </div>
          </Col>
          <Col xs={12} className="d-lg-none">
            <div className="d-flex justify-content-end">
              <Button
                className="d-lg-none mt-4"
                variant="outline-dark"
                onClick={() => setShowSidebar(true)}
                onMouseEnter={(e) => e.currentTarget.classList.add("bg-dark")}
                onMouseLeave={(e) => e.currentTarget.classList.remove("bg-dark")}
                onFocus={(e) => e.currentTarget.classList.add("shadow")}
                onBlur={(e) => e.currentTarget.classList.remove("shadow")}
              >
                <i className="fas fa-filter me-2 fs-5"></i>
                <span>Search Filters</span>
              </Button>
            </div>
          </Col>

          <Col xs={12} lg={9} className="text-start py-4">
            <Row className="mb-2">
              <Col>
                <div className="breadcrumb-container">
                  <a style={{ textDecoration: "none", fontSize: "13px", color: "black" }} href="/">Home</a><span className="divider"> <i style={{ fontSize: "12px" }} className="fa fa-angle-right"></i> </span>
                  <a style={{ textDecoration: "none", fontSize: "13px", color: "#368de9", fontWeight: "500" }} href={`/${category ? category.toLowerCase() : ''}`}>
                    <span>{category || "Products"}</span>
                  </a>
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              <h5 className="worktext mb-0 fw-bold">All Products</h5>
            </Row>

            <Row>
              {loading ? (
                <Col className="text-center pt-5">
                  <Spinner animation="border" variant="primary" />
                  <div className="mt-3">Loading Products...</div>
                </Col>
              ) : (
                sortedProducts.map((product) => (
                  <ProductCard key={product.id} product={product} />
                ))
              )}
            </Row>
            {!hasMore && !loading && <Col className="text-center mt-3">No more products found</Col>}
          </Col>
        </Row>
      </Container>

      <Offcanvas className="filter_sidebar" show={showSidebar} onHide={() => setShowSidebar(false)} placement="start">
        <Offcanvas.Header className="" closeButton>
          <Offcanvas.Title>
            <h6 className="sidebar-heading fw-bold fs-3 p-0 text-start">Filter by Category</h6>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="p-3">
            <Form>
              <Form.Check
                type="checkbox"
                id="category-all"
                label="All Categories"
                value="all"
                onChange={handleCategoryChange}
                checked={selectedCategories.length === categories.length}
                className="mb-1"
              />
              {categories.map((category) => (
                <Form.Check
                  key={category.name}
                  type="checkbox"
                  id={`category-${category.name}`}
                  label={category.name}
                  value={category.name}
                  onChange={handleCategoryChange}
                  checked={selectedCategories.includes(category.name)}
                  className="mb-1"
                />
              ))}
            </Form>

            <h6 className="fs-5 mt-4 mb-3">Price Range</h6>
            <Form.Group>
              <Form.Control
                as="select"
                className="border-light"
                onChange={handlePriceRangeChange}
              >
                <option value="all">All</option>
                <option value="0-100">0 - 100</option>
                <option value="100-1000">100 - 1000</option>
                <option value="1000-">1000+</option>
              </Form.Control>
            </Form.Group>

            <h6 className="fs-5 mt-4 mb-3">Sort by Price</h6>
            <Form.Group>
              <Form.Control
                as="select"
                className="border-light"
                onChange={handleSortChange}
              >
                <option value="default">Default</option>
                <option value="low-to-high">Price: Low to High</option>
                <option value="high-to-low">Price: High to Low</option>
              </Form.Control>
            </Form.Group>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Footer />
    </>
  );
}
