import React from 'react';
import { Card, Button, Col } from 'react-bootstrap';

const ProductCard = ({ product }) => {
    const finalPrice = Math.round(product.price - (product.price * product.offer) / 100);

    return (
        <Col
            key={product.id}
            xs={6}
            sm={4}
            md={3}
            lg={3}
            xl={3}
            className="productscardmain p-lg-2 p-md-2 p-sm-1 p-1 mb-1"
        >
            <a
                href={product.imageUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
            >
                <Card
                    className="border-0 rounded-3 shadow-sm d-flex flex-column h-100 product_card"
                >
                    <Card.Img
                        className="productcardimg1 pt-2"
                        variant="top"
                        src={product.image[0]}
                        alt={product.name}
                    />
                    <Card.Body className="p-2 d-flex flex-column justify-content-between bg-white">
                        <Card.Text>
                            <Card.Title className="cardproductname mb-1 fw-bold text-dark">
                                {product.name.length > 40 ? `${product.name.substring(0, 40)}...` : product.name}
                            </Card.Title>

                            <div className="mb-1">
                                {product.category}
                            </div>
                            <div className="d-flex flex-wrap mb-1">
                                <span className="text-danger text-decoration-line-through pe-1 fs-6">
                                    ₹{product.price}
                                </span>
                                <span className="fs-6 text-success">
                                    <div>(Offer: {product.offer}%)</div>
                                </span>
                            </div>
                            <div className="fs-5 fw-bold">
                                <span className="text-dark">₹{finalPrice}</span>
                            </div>
                        </Card.Text>
                    </Card.Body>
                    <Button
                        variant="dark"
                        className="mt-auto rounded-0 rounded-bottom-3 fs-6 py-2 px-4"
                    >
                        Buy Now
                    </Button>
                </Card>
            </a>
        </Col>
    );
};

export default ProductCard;
