import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./firebaseConfig";
import { Link } from "react-router-dom";

export default function CategoriesImage1() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    console.log("Setting up Firestore listener...");

    const unsubscribe = onSnapshot(
      collection(db, "categoriesimages1"),
      (snapshot) => {
        const imagesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log("Fetched images list:", imagesList);
        setImages(imagesList);
      },
      (error) => {
        console.error("Error fetching images:", error);
      }
    );

    return () => {
      console.log("Cleaning up Firestore listener...");
      unsubscribe();
    };
  }, []);

  return (
    <Container fluid>
      <Row>
        {images.map((category) => {
          console.log("Rendering category:", category);
          return (
            <Col
              key={category.id}
              xs={6}
              sm={6}
              md={3}
              lg={3}
              className="d-flex justify-content-center p-lg-2 p-1 p-sm-1"
            >
              <Link
                to={`/CategoryProduct?category=${encodeURIComponent(category.name)}`}
                className="text-decoration-none"
              >
                <img
                  src={category.image || "placeholder-image-url"}
                  alt={`Category ${category.name}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover"
                  }}
                />
              </Link>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
