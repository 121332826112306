import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Alert, Button, Form, Offcanvas } from "react-bootstrap";
import { db } from "./firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import ProductCard from "./ProductCard";
import Shopproduct1 from "./Shopproduct1";

export default function CategoryProduct() {
    const [products, setProducts] = useState([]);
    const [sortOrder, setSortOrder] = useState('default');
    const [priceRange, setPriceRange] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showSidebar, setShowSidebar] = useState(false);
    const [category, setCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const categorySnapshot = await getDocs(collection(db, "categories"));
                const categoryList = categorySnapshot.docs.map((doc) => doc.data());
                setCategories(categoryList);
            } catch (error) {
                console.error("Error fetching categories: ", error);
            }
        };

        fetchCategories();
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const categoryFromQuery = queryParams.get("category");
        setCategory(categoryFromQuery || "");

        const fetchProducts = async () => {
            try {
                let productQuery = collection(db, "products");

                if (categoryFromQuery && categoryFromQuery !== "All") {
                    productQuery = query(
                        productQuery,
                        where("category", "==", categoryFromQuery)
                    );
                }

                const querySnapshot = await getDocs(productQuery);
                const fetchedProducts = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setProducts(fetchedProducts);
            } catch (error) {
                setError("Error fetching products: " + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, [location.search]);

    const handleSortChange = (event) => {
        setSortOrder(event.target.value);
    };

    const handlePriceRangeChange = (event) => {
        setPriceRange(event.target.value);
    };

    const handleSearchChange = (query) => {
        setSearchQuery(query);
    };

    const handleCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        if (selectedCategory !== Shopproduct1) {
            navigate(`/product`);
        }
    };

    const filterProducts = (product) => {
        const finalPrice = Math.round(product.price - (product.price * product.offer) / 100);
        return (
            (selectedCategories.length === 0 || selectedCategories.includes(product.category)) &&
            (priceRange === "all" ||
                (priceRange === "0-100" && finalPrice >= 0 && finalPrice <= 100) ||
                (priceRange === "100-1000" && finalPrice > 100 && finalPrice <= 1000) ||
                (priceRange === "1000-" && finalPrice > 1000)) &&
            product.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    const sortedProducts = products
        .filter(filterProducts)
        .sort((a, b) => {
            const finalPriceA = Math.round(a.price - (a.price * a.offer) / 100);
            const finalPriceB = Math.round(b.price - (b.price * b.offer) / 100);

            if (sortOrder === "high-to-low") {
                return finalPriceB - finalPriceA;
            } else if (sortOrder === "low-to-high") {
                return finalPriceA - finalPriceB;
            }
            return 0;
        });

    if (loading) {
        return <div className="text-center mt-5">
            <Container className="d-flex flex-column align-items-center justify-content-center pt-5">
                <Spinner animation="border" variant="primary" />
                <p className="mt-3">Loading Product...</p>
            </Container>
        </div>;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <>
            <Navbar onSearch={handleSearchChange} />

            <Container fluid>
                <Row className="justify-content-start">
                    <Col xs={12} lg={3} className="d-none d-lg-block">
                        <div className="sidebar p-3">
                            <h6 className="sidebar-heading fs-5 fw-bold pb-2 p-0 text-start">Filter by Category</h6>
                            <Form.Check
                                type="checkbox"
                                id="category-all"
                                label="All Categories"
                                value="all"
                                onChange={handleCategoryChange}
                                checked={selectedCategories.length === categories.length}
                                className="mb-1"
                            />
                            {categories.map((category) => (
                                <Form.Check
                                    key={category.name}
                                    type="checkbox"
                                    id={`category-${category.name}`}
                                    label={category.name}
                                    value={category.name}
                                    onChange={handleCategoryChange}
                                    checked={selectedCategories.includes(category.name)}
                                    className="mb-1"
                                />
                            ))}
                            <Form.Group className="mt-3">
                                <Form.Label>Price Range</Form.Label>
                                <Form.Control as="select" onChange={handlePriceRangeChange}>
                                    <option value="all">All</option>
                                    <option value="0-100">0 - 100</option>
                                    <option value="100-1000">100 - 1000</option>
                                    <option value="1000-">1000+</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Label>Sort by Price</Form.Label>
                                <Form.Control as="select" onChange={handleSortChange}>
                                    <option value="default">Default</option>
                                    <option value="low-to-high">Price: Low to High</option>
                                    <option value="high-to-low">Price: High to Low</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </Col>
                    <Col xs={12} className="d-lg-none">
                        <div className="d-flex justify-content-end">
                            <Button
                                className="d-lg-none mt-4"
                                variant="outline-dark"
                                onClick={() => setShowSidebar(true)}
                                onMouseEnter={(e) => e.currentTarget.classList.add("bg-dark")}
                                onMouseLeave={(e) => e.currentTarget.classList.remove("bg-dark")}
                                onFocus={(e) => e.currentTarget.classList.add("shadow")}
                                onBlur={(e) => e.currentTarget.classList.remove("shadow")}
                            >
                                <i className="fas fa-filter me-2 fs-5"></i>
                                <span>Search Filters</span>
                            </Button>
                        </div>
                    </Col>

                    <Col xs={12} lg={9} className="text-start py-4">
                        <Row className="mb-2">
                            <Col>
                                <div className="breadcrumb-container">
                                    <a style={{ textDecoration: "none", fontSize: "13px", color: "black" }} href="/">Home</a><span className="divider"> <i style={{ fontSize: "12px" }} className="fa fa-angle-right"></i> </span>
                                    <a style={{ textDecoration: "none", fontSize: "13px", color: "#368de9", fontWeight: "500" }} href={`/${category.toLowerCase()}`}>
                                        <span>{category || "Products"}</span>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <h5 className="worktext mb-0 fw-bold">
                                    Category {category && ` - ${category}`}
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            {sortedProducts.length > 0 ? (
                                sortedProducts.map((product) => (
                                    <ProductCard key={product.id} product={product} />
                                ))
                            ) : (
                                <Col>No products found</Col>
                            )}
                        </Row>
                    </Col>

                    <Offcanvas className="filter_sidebar" show={showSidebar} onHide={() => setShowSidebar(false)} placement="start">
                        <Offcanvas.Header className="" closeButton>
                            <Offcanvas.Title>
                                <h6 className="sidebar-heading fs-3 fw-bold p-0 text-start">Filter by Category</h6>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className="p-0">
                            <div className="p-3">
                                <Form>
                                    <Form.Check
                                        type="checkbox"
                                        id="category-all"
                                        label="All Categories"
                                        value="all"
                                        onChange={handleCategoryChange}
                                        checked={selectedCategories.length === categories.length}
                                        className="mb-1"
                                    />
                                    {categories.map((category) => (
                                        <Form.Check
                                            key={category.name}
                                            type="checkbox"
                                            id={`category-${category.name}`}
                                            label={category.name}
                                            value={category.name}
                                            onChange={handleCategoryChange}
                                            checked={selectedCategories.includes(category.name)}
                                            className="mb-1"
                                        />
                                    ))}
                                </Form>

                                <h6 className="fs-5 mt-4 mb-3">Price Range</h6>
                                <Form.Group>
                                    <Form.Control
                                        as="select"
                                        className="border-light"
                                        onChange={handlePriceRangeChange}
                                    >
                                        <option value="all">All</option>
                                        <option value="0-100">0 - 100</option>
                                        <option value="100-1000">100 - 1000</option>
                                        <option value="1000-">1000+</option>
                                    </Form.Control>
                                </Form.Group>

                                <h6 className="fs-5 mt-4 mb-3">Sort by Price</h6>
                                <Form.Group>
                                    <Form.Control
                                        as="select"
                                        className="border-light"
                                        onChange={handleSortChange}
                                    >
                                        <option value="default">Default</option>
                                        <option value="low-to-high">Price: Low to High</option>
                                        <option value="high-to-low">Price: High to Low</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Row>
            </Container>
            <Footer />
        </>
    );
}
