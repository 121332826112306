import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

const LoginAdmin = ({ setAuth }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Dummy authentication logic
    if (username === "a" && password === "a") {
      setAuth(true);

      try {
        // Add login data to Firestore
        await addDoc(collection(db, "adminLogins"), {
          username: username,
          loginTime: new Date().toISOString(),
        });

        // Save username to local storage
        localStorage.setItem("username", username);
        localStorage.setItem("password", password);

        navigate("/Dashboard");
      } catch (e) {
        console.error("Error adding document: ", e);
        alert("Failed to save login data.");
      }
    } else {
      alert("Invalid credentials");
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Row className="w-100">
        <Col md={{ span: 6, offset: 3 }}>
          <Card className="shadow-lg p-4 rounded cardadminlogin">
            <Card.Body className="p-5">
              <h2 className="text-center mb-4">Admin Login</h2>
              <Form onSubmit={handleSubmit} className="text-start">
                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>

                <Button variant="dark" type="submit" className="w-100">
                  Login
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginAdmin;
